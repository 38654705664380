.podcast-tile {

    .image-wrap {
        position: relative;
    }

    &:hover {
        .btn-play-episode {
            opacity:1;
            transform: translateY(0);
        }
    }

    &.no-image {
        .btn-play-episode {
            background:$primary-color;
            svg {fill:#fff;}
        }
    }

    .btn-play-episode {
        position:absolute;
        cursor: pointer;
        z-index: 5;
        bottom:20px;
        right:20px;
        width:65px;
        height:65px;
        background:#fff;
        border-radius:50%;
        display:flex;
        justify-content:center;
        align-items:center;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35);
        transition:all .3s;
        opacity: 0;
        transform: translateY(15px);

        &:hover {
            transform:translate(0px) scale(1.07) !important;
        }

        svg {
            width:22px;
            transform:translateX(2px);
        }
    }

    &.no-image .title {
        margin-bottom:8px !important;
    }
    .author {
        margin-bottom:15px !important;
    }

    .date-tile {
        background-color: #e6e9ec;
    }

}