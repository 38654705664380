.elements__calltoactionelement, .elements__contentelement {
    .cta-content {
        @include rounded-corners;
        @include gradient;
        padding:20px;
        @include breakpoint(medium) {
            padding:30px;
            display:flex;
            justify-content:space-between;
            align-items:center;
        }
        &.Blue {
            background:$primary-color;
        }

        .title {
            font-family:$font-family-primary;
            font-size:24px;
            line-height:26px;
            font-weight:900;
            color:#fff;
            @include breakpoint(medium) {
                font-size:32px;
                line-height:34px;
                margin:0px;
            }
        }

    }
}