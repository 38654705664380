.FundraiserPage {
    .elements__herocarousel .slick-dots {
        bottom:160px;
    }

    .elements__featuredcontent .subtitle {
        font-size: 20px;
        line-height: 20px;
        @include breakpoint(medium) {
            font-size: 26px;
            line-height: 26px;
        }
    }
    .elements__featuredcontent .heading {
        margin-bottom: 0px;
    }

    .elements__herocarousel .slick-active .content {
        transform:translateY(-50px);
    }
    
    #footer {
        margin-top: 0px;
    }
}