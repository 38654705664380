.pagination {
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: center;
    color:$brand-dark;
    font-size:24px;
    margin:50px auto;
    @include breakpoint(medium) {
        margin:70px 0;
    }

    .fa {
        font-size: 12px;
        padding:0 10px;
    }

    .nav-btn {
        width:35px;
        display:inline-flex;
        justify-content:center;
        align-items:center;
        border-radius:5px;
        padding:2px 0px;
        color:$brand-dark;
        margin:0 7px;
        &:hover {
            background: #D5DFE3;
        }
    }

    .active {
        font-weight:900;
        background: #D5DFE3;
    }

    .prev-next {
        display:inline-flex;
        align-items:center;
        font-weight:900;
        margin:0 5px;
        color:$brand-dark;
        &:hover {
            span {text-decoration:underline;}
        }
    }

    .disabled {
        color:#4C6070;
    }
}