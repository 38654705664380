    // For First Slide Shadow
    .blog-slider {
        width: calc(100% + 20px);
        transform: translateX(-20px);

        .post-tile {
            @include breakpoint(small only) {
                width:300px;
            }
        }
    }

.slider-container-wrap {
    position:relative;
}

.elements__blogelement {
    padding-bottom:25px;
    @include breakpoint(large) {
        padding-bottom:65px;
    }

    .slider-container {
        padding:0px;
        width:calc(100vw - ((100vw - 100%) / 2));
    }

    .slick-list {
        overflow:visible;
    }

    .slick-track {
        display:flex;
    }

    .slick-arrow {
        display:none !important;
        transform:translateY(calc(-50% - 25px));
        @include breakpoint(medium) {
            display:inline-block !important;
        }

        &.slick-disabled {
            color:rgba(0,0,0,0.2);
        }
    }
    .slick-prev {
        left:20px;
        @include breakpoint(large) {
            left:30px;
        }
    }
    .slick-next {
        right:20px;
        @include breakpoint(large) {
            right:30px;
        }
    }
    .header {
        padding-bottom:20px;
        display:flex;

        @include breakpoint(medium) {
            align-items:center;
        }

        .button {
            display:none;
            margin-right:10px;
            @include breakpoint(medium) {
                display:inline-block;
            }
        }

        svg {
            width:34px;
            flex-shrink:inherit;
            @include breakpoint(medium) {
                width:38px;
            }
        }

    }


    .heading {
        padding:0 15px;
        margin:0px;
        font-size:32px;
        line-height:32px;
        @include breakpoint(medium) {
            font-size:40px;
            line-height:40px;
        }
    }
    .slick-slide {height:inherit;padding-bottom:35px;transition:all;}
    // .slick-slide:has(+ .slick-current) {
    //     transform:translateX(-30px);
    // }

    .btn-view-all-mobile {
        display:inline-block;
        transform:translateY(-10px);
        @include breakpoint(medium) {
            display:none;
        }
    }
}