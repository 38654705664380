@charset 'utf-8';
@import "klrc.scss";

@import 'settings';
@import 'foundation';
// @import 'motion-ui';
@import 'slick';
@import "fonts";
@import "fontawesome";
@import "regular";
@import "brands";

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-range-input;
// @include foundation-switch;
@include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
@include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
// @include motion-ui-transitions;
// @include motion-ui-animations;

@mixin rounded-corners {
    border-radius: 15px;
    overflow: hidden;
    @include breakpoint(medium) {
        border-radius: 20px;
    }
}
@mixin tile-padding {
    padding: 20px;
    @include breakpoint(medium) {
        padding:30px;
    }
    // @include breakpoint(large) {
    //     padding:50px 30px;
    // }
}
@mixin gradient {
    background: linear-gradient(to right, #FF912D 0%, #FF2D9B 100%);
}

@mixin box-shadow {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
}

@mixin tile-hover {
    transition:all .2s;
    &:hover {
        transform:scale(1.01);
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
    }
}

@import "type.scss";

@import "./partials/utilities.scss";
@import "./partials/button.scss";
@import "./partials/forms.scss";

@import "./partials/header.scss";
@import "./partials/footer.scss";
@import "./partials/pageheader.scss";
@import "./partials/menu.scss";
@import "./partials/datetile.scss";
@import "./partials/mediaplayer.scss";
@import "./partials/fullplayer.scss";
@import "./partials/mobileplayer.scss";
@import "./partials/blogactions.scss";
@import "./partials/podcasttile.scss";
@import "./partials/pagination.scss";

@import "./elements/hero.scss";
@import "./elements/blogslider.scss";
@import "./elements/insidersignup.scss";
@import "./elements/featuredevent.scss";
@import "./elements/objectgrid.scss";
@import "./elements/imageelement.scss";
@import "./elements/videoelement.scss";
@import "./elements/featuredcontent.scss";
@import "./elements/featuredblog.scss";
@import "./elements/podcastfeed.scss";
@import "./elements/calltoaction.scss";
@import "./elements/logoselement.scss";
@import "./elements/socialproof.scss";
@import "./elements/fundraiservitals.scss";
@import "./elements/contentelement.scss";
@import "./elements/podcastepisode.scss";
@import "./elements/eventtile.scss";


@import "./pages/blog.scss";
@import "./pages/events.scss";
@import "./pages/artists.scss";
@import "./pages/search.scss";
@import "./pages/podcast.scss";
@import "./pages/database.scss";
@import "./pages/fundraiser.scss";
@import "./pages/closings.scss";


.grid-container.narrow {
    max-width: 1200px;
}

.reveal {
    width: 100%;
    max-width:800px;
    @include breakpoint(medium) {
        padding:20px 50px 50px;
    }
}

.slick-arrow {
    cursor: pointer;
}
.slick-dots {
    .slick-active:only-child {
        display:none;
    }
}

.reveal {
    border:none;

    .close-button {
        position:absolute;
        top:18px;
        right:18px;
    }
}

.text-small {
    font-size:16px;
}

.cms-edit-link {
    position:fixed;
    right:20px;
    bottom:120px;
}

.ErrorPage {
    .main {
        p {
            font-family:$font-family-serif;
        }
    }
}

.responsive-embed iframe {
    @include rounded-corners;
}

img.left {
    float:left;
    margin-right:20px;
    margin-bottom:20px;
    @include breakpoint(medium) {
        margin-right:30px;
        margin-bottom:30px;
    }
}
img.right {
    float:right;
    margin-left:20px;
    margin-bottom:20px;
    @include breakpoint(medium) {
        margin-left:30px;
        margin-bottom:30px;
    }
}