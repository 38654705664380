p {
    font-size:16px;
    line-height:28px;
    @include breakpoint(medium) {
        font-size:20px;
        line-height:32px;
    }    
}

.text-large {
    font-size: 24px;
    line-height: 28px;
    @include breakpoint(medium) {
        font-size: 28px;
        line-height: 32px;
    }
}
.text-xlarge {
    font-size: 32px;
    line-height: 36px;
    @include breakpoint(medium) {
        font-size: 40px;
        line-height: 42px;
    }
}