.elements__contentelement {
    p, ol, ul {
        font-family: $font-family-serif;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 20px;
        @include breakpoint(medium) {
            font-size: 20px;
            line-height: 32px;
        }
    }
}