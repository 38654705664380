.Blog {
}

.BlogPost {

    .page-header .image-wrap:before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
    }

    .sub-head {
        display: flex;align-items: center;
    }

    .categories {
        margin-bottom:30px;
    }

    .btn-category {
        color:#fff;
        font-size:12px;
        font-weight:800;
        text-transform:uppercase;
        background:$body-light;
        padding:11px 22px;
        border-radius:21px;
        transition:.2s all;
        &:hover {
            opacity:.9;
        }
    }

    .share-wrap {
        display: inline-flex;
        width: 130px;
        justify-content: space-between;
        align-items: center;
        margin-left:30px;
    }

    .blog-content {
        margin-bottom:50px;
        @include breakpoint(medium) {
            margin-bottom:100px;
        }

        .blog-actions {
            @extend .grid-container;
            @extend .narrow;
        }
    }

    .blog-content .elements__contentelement {
        font-family:'Noto Serif';

        p, ul, ol {
            margin-bottom:20px;
            font-size:16px;
            @include breakpoint(medium) {
                font-size:20px;
                margin-bottom:30px;
            }
        }

        img.left {
            float:left;
            margin:0 20px 20px 0;
            @include breakpoint(medium) {
                margin:0 30px 30px 0;
            }
        
        }
    }
    .podcast-episode-element {
        margin:0px;
    }

    .dnadesign__elementaluserforms__model__elementform {
        max-width: 1200px;
        width: 100%;
        padding-top:20px;
        padding-bottom:20px;
    }
}
.blog-masonry {
    position: relative;

    .post-tile {
        @include breakpoint(small only) {
            margin-bottom:20px;
        }
    }
}
// .blog-masonry {
//     -webkit-column-count: 1;
//     -moz-column-count:1;
//     column-count: 1;
//     -webkit-column-gap: 1em;
//     -moz-column-gap: 1em;
//     column-gap: 1em;
//     padding: 0;
//     -moz-column-gap: 1.5em;
//     -webkit-column-gap: 1.5em;
//     column-gap: 20px;
//     @include breakpoint(medium) {
//         -webkit-column-count: 2;
//         -moz-column-count:2;
//         column-count: 2;
//         column-gap: 30px;
//     }
//     @include breakpoint(large) {
//         -webkit-column-count: 3;
//         -moz-column-count:3;
//         column-count: 3;
//         column-gap: 40px;
//     }
//     @include breakpoint(xlarge) {
//         -webkit-column-count: 4;
//         -moz-column-count:4;
//         column-count: 4;
//         column-gap: 50px;
//     }
// }

.masonry-item {
    position:absolute;
    // padding-bottom:30px;
}
.blog-post-item {
    display: inline-block;
    // margin: 0 0 20px;
    width: 100%;
	transition:1s ease all;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    // @include breakpoint(medium) {
    //     margin: 0 0 30px;
    // }
    // @include breakpoint(large) {
    //     margin: 0 0 40px;
    // }
    // @include breakpoint(xlarge) {
    //     margin: 0 0 50px;
    // }
}
.post-tile {
    display:block;
    position:relative;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
    height:100%;
    transition:all .2s;

    &:hover {
        transform:scale(1.01);
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
    }

    // @include tile-hover;

    &.slider {
        margin-left:22px;
        @include breakpoint(medium) {
            width:340px;
        }
    }

    .post-tile-content {
        padding:30px;
        border-radius:0 0 20px 20px;
        background:#fff;

        p {
            font-family: $font-family-serif;
            color:$body-light;
        }
    }
    img {
        width:100%;
        border-radius:20px 20px 0 0;
    }
    .title {
        font-size:28px;
        line-height:28px;
        font-weight:800;
        @include breakpoint(medium) {
            font-size:32px;
            line-height:32px;
        }
        color:$black;
    }
    p.author {
        font-size:22px;
        line-height:22px;
        margin:0px;
        color:$black;
        font-family: $font-family-sans-serif;
    }

    .date-tile {
        position:absolute;
        top:15px;
        right:15px;
        z-index:5;
    }
    .tile-link {
        display:block;
        height:100%;
        width:100%;
        position:absolute;
        top:0;
        left:0;
        z-index:1;
    }
    .btn-praise {
        @include breakpoint(small only) {
            font-size:13px;
        }
    }
    .cta-content {
        display:none;
    }
}
// Prayer & Hope Stories Style/Theme
.post-tile.no-image {
    .post-tile-content {
        border-radius:20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .title {
        padding-right:45px;
        margin-bottom:8px;
    }
    .author {
        margin-bottom:20px;
    }
}
