.elements__featuredblog {

    .heading {
        display: inline-flex;
        align-items: center;
        margin-bottom:0px;
    }
    
    .header {
        display: flex;
        align-items: center;
        padding-bottom:20px;
        .button {
            margin:0 0px 0px 10px;
            display:none;
            @include breakpoint(medium) {
                display:inline-flex;
            }
        }
        h2 {
            margin-right:20px;
        }
    }

    .icon {
        height:30px;
        margin-right:5px;
        @include breakpoint(medium) {
            height:38px;
        }
    }
    .title {
        font-size:36px;
        line-height:38px;
        margin:0 0 2px;
    }
    .subtitle {
        font-size:28px;
        line-height:30px;
    }
    .subheading {
        text-transform:uppercase;
        font-weight:900;
        font-size:16px;
        color:#4C6070;  
        margin:0 0 20px;
    }
    .featured-content {
        display:block;
        @include rounded-corners;
        @include box-shadow;
        background:#fff;
        transition:all .2s;
        @include breakpoint(large) {
            display:flex;
            flex-direction:row-reverse;
        }
        &:hover {
            transform:scale(1.01);
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
        }

    }

    .featured-content__image {
        width:100%;
        background-position:center;
        background-size:cover;
        // @include breakpoint(medium) {
        //     min-height:450px;
        // }
        @include breakpoint(large) {
            min-height:550px;
        }

        .image {
            display:block;
            @include breakpoint(large) {
                display:none;
            }
        }
    }

    .featured-content__content {
        @include tile-padding;
        height:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        // @include breakpoint(medium) {
        //     min-width:350px;
        //     width:350px;
        // }
        @include breakpoint(large) {
            min-width:400px;
            width:400px;
        }
        h3 {
            font-weight:800;
        }

        p {
            font-family: $font-family-serif;
            color:$body-light;
        }
    }

    // .image {
    //     min-height:100%;
    //     max-width:none;
    // }
}
