.elements__socialproofelement {

    .content-wrap {
        display:flex;
        align-items:center;
        justify-content:center;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center center;
        flex-direction:column;
        padding:100px 20px 160px;
        @include breakpoint(large) {
            padding:140px 30px 160px;
        }
    }

    .icon {
        width:115px;
        @include breakpoint(medium) {
            width:150px;
        }
    }

    .content {
        text-align:center;
        color:#fff;
        max-width:730px;
        margin:0 auto 30px;
    }

    h2,h3,h4 {
        font-size:48px;
        line-height: 50px;
        margin:20px 0;
        @include breakpoint(medium) {
            font-size:68px;
            line-height: 70px;
        }
    }

    .donation-slider {
        width:100%;
        margin-top:20px;
        @include breakpoint(medium) {
            margin-top:40px;
        }
    }

    .donation-item {
        display:flex;
        align-items:center;
        justify-content:center;
        color:$white;
        padding:0 20px 30px;
        .amount {
            position: relative;
            display:flex;
            align-items:center;
            justify-content:center;
            font-size:30px;
            line-height:38px;
            width:77px;
            height:77px;
            min-width:77px;
            border-radius:50%;
            background:$primary-color;
            color: #fff;
            font-weight:900;
            letter-spacing:-0.5px;
            margin-right:12px;

            @include breakpoint(large) {
                width:95px;
                height:95px;
                min-width:95px;
                font-size:36px;    
            }
            &.medium {
                font-size:24px;
                @include breakpoint(large) {
                    font-size:30px;
                }
            }
            &.large {
                font-size:20px;
                @include breakpoint(large) {
                    font-size:24px;
                }
            }
        }
        .monthly-badge {
            position:absolute;
            right:-15px;
            bottom:-20px;
            width:55px;
        }
        .title {
            font-size:26px;
            line-height:28px;
            font-weight:900;
            margin:0px;
            @include breakpoint(large) {
                font-size:32px;
                line-height:34px;
                }
        }
        .location {
            font-size:23px;
            line-height:25px;
            font-weight:100;
            margin:0px;
            @include breakpoint(large) {
                font-size:28px;
                line-height:30px;
            }
        }
    }
}