.podcast-episode-element {
    @include rounded-corners;
    background-size: cover;
    margin:50px 0;
    @include breakpoint(medium) {
        margin:80px 0;
    }

    .btn-pause-episode, .btn-play-episode {
        fill:#fff;
        margin:0 auto;
        @include breakpoint(medium) {
            margin:0;
        }
        svg {
            width:100px;
        }
    }
    .btn-pause-episode {
        display:none;
    }

    &.playing {
        .btn-play-episode {
            display:none;
        }
        .btn-pause-episode {
            display:block;
        }
    }

    .inner {
        @include tile-padding;
        width:100%;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        background:rgba(0,0,0,0.35);
        text-align:center;
        @include breakpoint(medium) {
            display:flex;
            align-items:center;
            text-align:left;
        }
    }

    .info {
        margin-top:20px;
        @include breakpoint(medium) {
            margin-top:0px;
            padding:0 15px;
            display:flex;
            justify-content:space-between;
            align-items:center;
            width:100%;
        }

        p.title {
            color:#fff;
            font-size:32px;
            line-height:33px;
            font-weight:900;
            margin:0;
            padding-bottom:5px;
            font-family:$font-family-primary;
            @include breakpoint(medium) {
                font-size:36px;
                line-height:36px;
            }
        }
    
    }

    .podcast-link {
        color:#fff;
        font-size:28px;
        font-family:$font-family-primary;
        &:hover {
            text-decoration:underline;
        }
    }

    img {
        width:200px;
        border-radius:15px;
    }
}
