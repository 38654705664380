.elements__featuredcontent {

    .heading {
        display:flex;
        align-items:center;
    }

    .icon {
        height:28px;
        margin-right:5px;
        @include breakpoint(medium) {
            height:38px;
        }
    }
    .title {
        font-size:36px;
        line-height:38px;
        margin:0 0 2px;
    }
    .subtitle {
        font-size:28px;
        line-height:20px;
        @include breakpoint(medium) {
            font-size:28px;
            line-height:30px;
        }
    }
    .featured-content {
        @include rounded-corners;
        @include box-shadow;
        background:#fff;
        transition:all .2s;
        @include breakpoint(large) {
            display:flex;
            flex-direction:row-reverse;
        }
    }

    .featured-content__image {
        width:100%;
        height:100%;
        background-position:center;
        background-size:cover;
        @include breakpoint(large) {
            min-height:550px;
        }

        .image {
            display:block;
            @include breakpoint(large) {
                display:none;
            }
        }
    }

    .featured-content__content {
        @include tile-padding;
        height:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        @include breakpoint(large) {
            min-width:400px;
            width:400px;
        }

        p {
            font-family: $font-family-serif;
        }
    }

    .featured-content__button {
        margin-top:15px;
        @include breakpoint(medium) {
            margin-top:30px;
        }
    }

    // .image {
    //     min-height:100%;
    //     max-width:none;
    // }
}
