.DatabasePage {
    .search-form {
        position:relative;
        padding-right:90px;
        input {
            box-shadow:none;
            border-radius:30px 0px 0px 30px;
            padding-left:20px;
        }
        .button {
            position:absolute;
            right:0px;
            top:0px;
            width:110px;
            height:44px;
        }
    }

    .filter-dropdown {
        font-weight:900;
        width:calc(50% - 2px);

        @include breakpoint(medium) {
            width:calc(50% - 10px);
        }

        @include breakpoint(large) {
            width:240px;
        }

        &:last-child {
            @include breakpoint(medium) {
                margin-left:10px;
            }
        }
    }

    .non-profit-set {
        ul {
            list-style:none;
            padding:0px;
            margin:0px;
            margin-bottom:30px;
    
            a {
                color:$brand-dark;
                font-weight:900;
                &:hover {
                    color:$brand-dark;
                    text-decoration:underline;
                }
            }
        }
    }


    .org-details {
        p {
            font-family:$font-family-serif;
        }
    }

}