.elements__herocarousel {
    height:calc(100vh - 75px);
    width:100%;
    position:relative;
    padding:0px !important;
    background:#000;

    &.short {
        height:640px;
    }

    .slick-list, .slick-track {
        height:100%;
    }

    .slick-arrow {
        display:none !important;
        @include breakpoint(medium) {
            display:inline-block !important;
        }
    }

    .slick-dots {
        position:absolute;
        bottom: 190px;
        width:100%;
        text-align:center;
        z-index:5;
        margin:0px;
        padding:0px;
        list-style-type:none;

        @include breakpoint(medium) {
            bottom: 120px;
        }

        li {
            position:relative;
            cursor:pointer;
            opacity:.5;
            display:inline-block;
        }

        li:before {
            content: "";
            background: #fff;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: block;
            margin: 0 6px;
        }
        button {display:none;}

        .slick-active {
            opacity:1;
        }
    }

    .hero-carousel-item {
        position: relative;
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat;
        display:flex;
        align-items:center;
        justify-content:center;
        height:100%;

        &:before {
            position: absolute;
            content: '';
            background: rgba(0,0,0,0);
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            z-index: 1;
        }

    }

    // .slick-slider:not(.slick-initialized) .slick-slide:not(:first-of-type) .content {
    //     opacity:0;
    //     transform:translateY(100px);
    //     transition:all .3s;
    //     transition-delay:.7s;
    // }

    .content {
        opacity:0;
        transform:translateY(100px);
        transition:all .3s;
        transition-delay:.7s;
        text-align:center;
        z-index:5;
        padding:0 30px 100px;
        @include breakpoint(medium) {
            padding:0 70px;
        }
        
        h1,h2,h3,h4,h5,h6 {
            color:#fff;

            @include breakpoint(medium) {
                font-size:68px;
                line-height: 72px;
    
            }
        }
        p {
            font-size:28px;
            line-height: 32px;
            color:#fff;
            @include breakpoint(medium) {
                font-size:40px;
                line-height: 42px;
            }
        }

        .button {
            margin:10px;
        }
    }

    .slick-active .content {
        opacity:1;
        transform:translateY(0px);
    }

    .title {
        color:#fff;
        font-size:48px;
        line-height: 52px;

        @include breakpoint(medium) {
            font-size:68px;
            line-height: 72px;
        }
    }


    .video {
        position:absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @supports (object-position: center) {
            right: unset;
            bottom: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &:before {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0);
            z-index:1;
        }
    }

    .slick-prev {
        @include breakpoint(large) {
            left:50px;
        }
    }
    .slick-next {
        @include breakpoint(large) {
            right:50px;
        }
    }

}
