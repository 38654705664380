.button {
    display:inline-flex;
    align-items:center;
    justify-content:center;
    font-weight:700;
    height:40px;
    margin:0px;
    text-wrap:nowrap;
    transition:all .3s;
    font-family:$font-family-primary;

    &:hover {
        background-image: linear-gradient(rgb(0 0 0/10%) 0 0) !important;
    }
    &.gradient {
        position: relative;
        z-index:5;
        overflow: hidden;
        transition:all .3s;
        border:none;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0);
            z-index:-1;
            transition:all .2s;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #FF912D 0%, #FF2D9B 100%);
            z-index:-1;
        }
        &:hover:after {
            background:rgba(0,0,0,0.15);
        }
    }

    &.hollow {
        border-width:2px;
    }

    &.primary, &.prayer, &.pray, &.hope {
        color:#fff;
        &:hover {
            color:#fff;
        }
    }

    &.medium {
        @include breakpoint(medium) {
            font-size:20px;
            height:46px;
        }
    }
    &.large {
        padding:22px 25px 22px 25px;
        @include breakpoint(medium) {
            font-size:24px;
            height:60px;    
            padding:17px 26px;
        }
    }

    &.short {
        height:33px;
        font-size:14px;
    }

    &.wide {
        padding-left:30px;
        padding-right:30px;
    }
    &.wider {
        padding-left:40px;
        padding-right:40px;
    }
    &.disabled {
        cursor:default;
        color:#fff !important;
    }
}


.slick-arrow {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    z-index:5;
    width:58px;
    height:58px;
    border-radius:50%;
    background:#fff;
    font-size:20px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.10));
}
.slick-prev {
    left:20px;
}
.slick-next {
    right:20px;
}
