.elements__fundraiservitals {
    position: relative;
    z-index:8;
    color: $brand-dark;

    &.offset {
        transform: translateY(-50%);
    }

    .grid-container {
        max-width:1400px;
    }

    .fundraiser-wrap {
        @include rounded-corners;
        @include box-shadow;
        padding:20px;
        background-color: #fff;
        @include breakpoint(medium) {
            padding: 40px;
        }
    }

    .header, .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fund-value, .stat-value {
            font-size:24px;
            line-height: 26px;
            font-weight:900;
            margin:0px;
            display:inline-block;
            letter-spacing:-1px;
            @include breakpoint(medium) {
                font-size: 36px;
                line-height: 40px;
            }
            @include breakpoint(large) {
                font-size: 48px;
                line-height: 52px;
            }
        }
        .fund-label, .stat-label {
            font-size:12px;
            line-height: 14px;
            margin:0px;
            letter-spacing:-1px;
            display:inline-block;
            margin-left:4px;
            font-weight:300;
            @include breakpoint(medium) {
                font-size: 20px;
                line-height: 22px;
                margin-left:8px;
                // font-weight:300;
            }
            @include breakpoint(large) {
                font-size: 32px;
                line-height: 34px;
            }
        }
    }

    .footer {
        flex-wrap:wrap;
        @include breakpoint(medium) {
            flex-wrap:nowrap;
        }
        .stat-value {
            font-size:18px;
            line-height: 20px;
            @include breakpoint(medium) {
                font-size: 24px;
                line-height: 26px;
            }
            @include breakpoint(large) {
                font-size: 36px;
                line-height: 40px;
            }
        }
        .stat-label {
            font-size:12px;
            line-height: 12px;
            @include breakpoint(medium) {
                font-size: 18px;
                line-height: 20px;
            }
            @include breakpoint(large) {
                font-size: 24px;
                line-height: 26px;
            }
        }

        .partnership-goal {
            font-size:16px;
            line-height: 16px;
            @include breakpoint(medium) {
                font-size: 20px;
                line-height: 20px;
            }
            @include breakpoint(large) {
                font-size: 24px;
                line-height: 24px;
            }
        }
    }
    .footer-left {
        display: flex;
        justify-content:space-between;
        width:100%;
        align-items:center;
        @include breakpoint(medium) {
            width:auto;
        }
    }
    .footer-right {
        width:100%;
        margin-top:10px;
        text-align:center;
        @include breakpoint(medium) {
            width:auto;
            display: flex;
            align-items: center;
            justify-content:flex-end;
            margin-top:0px;
        }
    }

    .footer-stat {
        white-space:nowrap;
        &:first-child {
            margin-right:10px;
            @include breakpoint(medium) {
                margin-right:25px;
            }
        }
    }

    .matching-challenge {
        font-size:20px;
        line-height: 22px;
        color:#FF912D;
        font-weight:900;
        margin:0px;
        letter-spacing:-1px;
        @include breakpoint(medium) {
            font-size:32px;
            line-height: 32px;
            margin-right:10px;
        }
    }


    .progress-bar {
        position:relative;
        background-color: #DCE3E7;
        height:36px;
        width:100%;
        margin:10px 0 20px;
        transition:all 1s ease;
        transition-delay: 2s;
        @include breakpoint(medium) {
            height: 52px;
        }
    }
    .progress-bar-fill {
        position:absolute;
        left:0px;
        top:0px;
        background-color: $primary-color;
        height:100%;
        width: 0%;
        max-width:100% !important;
        // min-width:20px;
    }
    #progress-value {
        position:absolute;
        font-size:22px;
        color:#fff;
        right:12px;
        top:50%;
        font-weight:900;
        transform: translateY(-50%);
        @include breakpoint(medium) {
            font-size:28px;
        }
    }
}