.elements__objectgrid {
    .slick-slide {
        padding:0 10px 32px;
    }
}
.grid-tile {
    display:block;
    @include box-shadow;
    @include rounded-corners;
    background:#fff;
    height:100%;
    overflow:hidden;
    transition:all .2s;

    &.has-link {
        &:hover {
            transform:scale(1.01);
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
        }
    }

    .grid-tile__content {
        background:#fff;
        padding:20px 15px;
        text-align:center;
        @include breakpoint(medium) {
            padding:30px 20px;
        }
    }
    .title {
        font-size:24px;
        line-height: 26px;
        font-weight:900;
        margin-bottom:8px;
        color:$brand-dark;
        @include breakpoint(large) {
            font-size:32px;
            line-height: 34px;
        }
    }
    .subtitle {
        font-size:18px;
        line-height: 20px;
        font-weight:400;
        color:$brand-dark;
        margin:0px;
        @include breakpoint(large) {
            font-size:22px;
            line-height: 24px;
        }
    }
}