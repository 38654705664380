.d-flex {
    display:flex;
}
.justify-content-center {
    justify-content:center;
}
.mt-1 {
    margin-top: 10px !important;
}
.mt-0 {
    margin-top: 0px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.mb-1 {
    margin-bottom: 10px !important;
}
.mt-2 {
    margin-top: 20px !important;
}
.mb-2 {
    margin-bottom: 20px !important;
}
.mt-3 {
    margin-top: 30px !important;
}
.mb-3 {
    margin-bottom: 30px !important;
}


@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.3);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.3);
      box-shadow: 0 0 0 0 rgba(0,0,0, 0.3);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
        box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
  }
  