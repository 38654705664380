.mobile-player {
    position:relative;
    display:block;
    opacity: 0;
    height: 0px;
    transition: .3s all;
    transition-delay: .4s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    overflow: hidden;
    border-radius:20px;

    @include breakpoint(large) {
        display:none;
    }

    .header {
        display:flex;
        position: relative;
        justify-content:center;
        align-items:center;
        border-radius: 20px 20px 0px 0px;
        background: rgba(0, 0, 0, 0.25);
        height:60px;
        padding:0px 20px;

        .btn-minimize-player {
            position:absolute;
            right:20px;
            top:20px;
        }

        .button {
            margin:0 5px;
            background:rgba(255,255,255,.5);
            color:rgba(0,0,0,.5);

            &.active {
                background:#fff;
            }
        }
    }

    .on-air-header {
        display:flex;
        justify-content:center;
        padding:20px 0;
    }
    .btn-about-show, .on-air-live {
        display:none;
    }

    .player-main-stream, .player-main-podcast {
        max-height:100%;
        overflow-y:auto;
        padding-bottom:60px;
    }

    .current-media {
        .on-air-wrap {
            flex-wrap:wrap;
            justify-content:center;
            padding-right:0px;
            .album-art {
                width: 250px;
                height: 250px;
            }
        }
        .show-info {
            width: 100%;
            padding: 20px;
            text-align: center;

            .subheading {
                font-size:13px;
                line-height:14px;
                margin-bottom:5px;
            }

            .title {
                font-size:32px;
                line-height:32px;
            }
            .subtitle {
                font-size:20px;
                line-height:22px;
            }
        }
        .btn-play-pause svg {
            width:80px;
            height:80px;
        }
        .controls .btn-vote {
            display:block;
            svg {height:37px;}
        }
        .btn-expand-player {
            display:none;
        }
    }

    .main-left {
        transition:all .3s;
        &.blur {
            filter: blur(40px);
        }

    }

    .btn-show-mobile-recents, .btn-view-more-mobile {
        display:block;
        width:150px;
        margin:20px auto;
    }
    .btn-show-mobile-episodes, .btn-show-mobile-shows {
        display: inline-block;
        margin:20px 5px;
        width:130px;
    }

    .main-right {
        position:absolute;
        width:100%;
        bottom:0px;
        left:0;
        background:rgba(119,119,119,.5);
        height:0px;
        transition:all .3s;
        overflow:hidden;
        border-radius: 0 0 20px 20px;

        &.open {
            height:calc(100% - 60px);
        }

        .heading .button {display:none;}

        .btn-mobile-close-recents, .btn-mobile-close-episodes, .btn-mobile-close-shows {
            svg {
                width:30px;
                height:30px;
            }
        }
    }

    .footer {
        text-align:center;
        padding:20px;
    }


    .recently-played-wrap {
        padding:25px 20px 0px;
        height:calc(100% - 80px);
        overflow:hidden;
        
        .heading {
            display:flex;
            align-items: center;
            justify-content: space-between;
            font-size:30px;
            line-height:30px;
            color:#fff;
            font-weight:900;
            margin-bottom:20px;
        }
    }

    .recently-played-list {
        height:100%;
        overflow:hidden;
        overflow-y:scroll;
    }
    .recently-played-item {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:20px;
        color: #fff;
        padding-right:0px;

        &:last-child {
            margin-bottom:80px;
        }

        // Hide the first item in the list since it's shown in the main section
        &:not(.podcast-tile):first-child {
            display:none;
        }
        .left-content {
            display:flex;
            align-items:center;
            padding-right:10px;
        }
        .album-art {
            width: 90px;
            height: 90px;
            margin-right:10px;
        }
        .recently-played-item-title {
            font-size:18px;
            line-height:20px;
            font-weight:900;
            display:block;
            color:#fff;
            &:hover {
                text-decoration:underline;
            }
        }
        .recently-played-item-artist {
            font-size:11px;
            line-height:11px;
            margin-bottom:5px;
            display:block;
            color:#fff;
            &:hover {
                text-decoration:underline;
            }
        }
        .recently-played-item-time {
            font-size:12px;
        }

    }

    .podcast-controls {
        display:flex;
    }
    .main-podcast {

        .recently-played-wrap {
            height:100%;
        }
        .on-air-header .title {
            font-size:18px;
            line-height:18px;
        }
    }
    .mobile-podcasts-list {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0;
        background: rgba(119,119,119,.5);
        height: 0px;
        transition: all .3s;
        overflow: hidden;
        border-radius: 0 0 20px 20px;
        z-index:6;

        &.open {
            height: calc(100% - 60px);
        }

        .recently-played-wrap {
            height:100%;
        }

        .left-content {
            padding-right:0px;
        }
        .recently-played-item-title {
            font-size:16px;
            line-height:18px;
            margin-bottom:4px;
        }
        
        .btn-mobile-close-shows {
            svg {
                width:30px;
                height:30px;
            }
        }

    }


}