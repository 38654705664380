.elements__logoselement {
    .logo {
        display:inline-block;
        padding:15px;
        filter: grayscale(100%);
        opacity: 0.9;
        transition: all 0.3s ease-in-out;
        @include breakpoint(medium) {
            padding:20px;
        }
        &:hover {
            filter: grayscale(0%);
            opacity: 1;
        }
    }
}