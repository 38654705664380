.elements__insidersignup {
    background: linear-gradient(111deg, #FFC42D 0.73%, #FF2D9B 93.59%);
    padding:40px 0;
    margin-bottom:50px;
    @include breakpoint(large) {
        margin-bottom:90px;
        padding:20px 0;
    }

    #email-signup-form {
        min-width:580px;
    }

    &.expanded {
        form {
            display:block;
            width:100%;
            margin-top:20px;
            text-align:center;

            input {
                margin-bottom:10px;
            }
        }
        .cell {
            flex-wrap:wrap;
        }

        .btn-expand-signup {
            display:none;
        }
        .btn-submit-signup {
            display:inline-block;
        }
        #email-signup-message {
            width:100%;
            text-align:center;
            margin-top:20px;
        }
    }

    .cell {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-wrap:wrap;
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    .heading {
        display:inline-block;
        text-align:center;
        @include breakpoint(medium) {
            text-align:left;
            margin:0px;
            padding-right:20px;
        }
        p {
            font-size:20px;
            line-height:22px;
            color:#fff;
            strong {
                font-weight:900;
            }
            @include breakpoint(medium) {
                margin:0px;
            }
            @include breakpoint(xxxlarge) {
                font-size:30px;
                line-height:30px;
            }
        }
    }

    form {
        display:none;
        padding:0 10px;
        &.show {
            display:block;
        }
        @include breakpoint(xxlarge) {
            display:inline-block;
        }
    }

    input {
        display:inline-block;
        color:#fff;
        border-radius: 6px;
        border: 1px solid #FFF;
        background: rgba(255, 255, 255, 0.00);
        font-size:12px;
        margin:0px;

        @include breakpoint(medium) {
            max-width:120px;
        }

        &::placeholder {
            color:#fff;
        }

        &:focus {
            background: rgba(255, 255, 255, 0.00);
            border-color:#fff;
            box-shadow:none;
        }

    }
    #email-signup-message {
        display:none;
        color:#fff;
        font-size:20px;
        margin:0px;
        padding:0px;
        // text-align:center;
        // @include breakpoint(medium) {
        //     text-align:left;
        // }
    } 

    .button {width:232px;}

    #email-signup-email {
        min-width:180px;
    }

    .btn-expand-signup {
        display:inline-block;
        @include breakpoint(xxlarge) {
            display:none;
        }        
    }
    .btn-submit-signup {
        display:none;
        @include breakpoint(xxlarge) {
            display:inline-block;
        }
    }
}
