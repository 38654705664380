.featured-event {
    position: relative;
    display:block;
    @include rounded-corners;
    overflow:hidden;
    margin-bottom:50px;
    transition:all .2s;
    @include box-shadow;

    &:hover {
        transform:scale(1.01);
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
    }

    @include breakpoint(medium) {
        margin-bottom:80px;
    }
    @include breakpoint(large) {
        margin-bottom:100px;
    }

    &:before {
        content: "";
        display: block;
        background:rgba(0,0,0,0.5);
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        @include rounded-corners;
    }

    .subtitle {
        display:block;
        position:absolute;
        top:0px;
        left:0px;
        text-transform:uppercase;
        font-weight:900;
        font-size:20px;
        color:#fff;
        @include tile-padding;

        // @include breakpoint(medlarge) {
        //     display:block;
        // }
    }

    .mobile-image {
        display: block;
        min-width:100%;
        @include breakpoint(medlarge) {
            display: none;
        }
    }
    .desktop-image {
        display: none;
        width:100%;
        @include breakpoint(medlarge) {
            display: block;
        }
    }

    .title {
        font-size:38px;
        line-height: 38px;
        margin-bottom:10px;
        @include breakpoint(medium) {
            font-size:50px;
            line-height:50px;
        }
        @include breakpoint(xlarge) {
            font-size:56px;
            line-height:56px;
        }
    }
    .event-details {
        font-size:20px;
        line-height: 20px;
        font-weight:300;
        @include breakpoint(medium) {
            font-size:30px;
            line-height: 30px;
        }
        @include breakpoint(xlarge) {
            font-size:36px;
            line-height: 36px;
        }
    }
    .event-details-mobile {
        margin-top:10px;

        .time {
            font-weight:900;
            font-size:24px;
            line-height:24px;
            color:#fff;
            margin-bottom:5px;
        }
        .venue {
            font-weight:300;
            font-size:24px;
            line-height:24px;
            margin-bottom:5px;
        }
        .city {
            font-weight:300;
            font-size:20px;
            line-height:20px;
            margin-bottom:0px;
        }
    }

    .content-wrap {
        position: absolute;
        left:0px;
        bottom:0px;
        width:100%;
        @include tile-padding;
        @include breakpoint(medium) {
            display:flex;
        }
    }
    .content {
        color:#fff;
        display:inline-block;

        h2,h3,h4,p {color:#fff; margin:0px;}
    }
    .date-tile {
        &.large {
            display:none;
            margin-right:20px;
            margin-bottom:40px;
            @include breakpoint(medlarge) {
                margin-bottom:0px;
                display:flex;
            }    
        }

        &.small {
            display:flex;
            position:absolute;
            right:20px;
            top:20px;
            @include breakpoint(medlarge) {
                display:none;
            }
        }
    }

    .button-wrap {
        margin-top:10px;
        @include breakpoint(medium) {
            margin-top:0px;
            flex: auto;
            text-align: right;
            display: flex;
            justify-content: end;
            align-items: center;
        }
    }

    .action-buttons {
        margin-top:20px;
        @include breakpoint(large) {
            padding-left:20px;
            display:flex;
            flex-grow:1;
            justify-content: flex-end;
        }
    }

    .btn-learn-more {
        margin-right:10px;
        @include breakpoint(medlarge) {
            display:none;
        }
    }
    .btn-view-all-events {
        align-self:center;
    }
}