#footer {
    background:#56B3E4;
    color:#fff;
    margin-top:50px;
    padding:50px 0 0;
    @include gotham-narrow-light(19px,25px);
    
    @include breakpoint(medium) {
        padding:40px 0 0;
    }
    @include breakpoint(large) {
        padding:60px 0 0;
    }

    strong {
        font-weight:900;
    }

    .logo {
        display:block;
        margin-bottom:24px;
    }

    p {
        font-size:18px;
        line-height:22px;
    }
    a:not(.social-link):hover {text-decoration:underline;}

    .text-small {
        font-size:14px;
    }

    .footer-top-row {
        padding-bottom:20px;
    }
    
    .footer-middle-row {
        padding:40px 0;
        border-top:1px solid #fff;

        .right-col {
            display:none;
            text-align:right;
            @include breakpoint(large) {
                display:block;
            }
            ul {
                list-style-type:none;
                margin:0;
                padding:0;
            }
            li {
                display:inline-block;
            }
            a {
                margin-left:10px;
                color:#fff;
                font-size:16px;
                line-height:20px;
                @include breakpoint(large) {
                    margin-left:20px;
                    font-size:18px;
                    line-height:22px;
                }
            }
        }
    }
    .app-social-wrap {
        display:flex;
        flex-wrap:wrap;
        img {
            margin-right:20px;
            .last {
                margin-right:0px;
            }
            @include breakpoint(small only) {
                width:150px;
            }
        }
    }

    .footer-bottom {
        padding:20px 0 100px;
        background: rgba(0, 0, 0, 0.12);

        .right-col {
            @include breakpoint(medium) {
                text-align:right;
            }
        }
        a {
            color:inherit;
            &:hover {
                text-decoration:underline;
            }
        }
    }

    .footer-menu {
        display:flex;
        justify-content:space-between;
        ul {
            list-style-type:none;
            padding:0px;
            margin:0px;
            color:#fff;
            margin-right:15px;

            li {
                margin-bottom:6px;
                line-height:1.1;
                a {
                    display:block;
                    font-size:16px;
                    line-height:20px;
                    color:#fff;
                    @include breakpoint(medium) {
                        font-size:16px;
                        line-height:22px;
                    }
                    @include breakpoint(large) {
                        font-size:18px;
                        line-height:30px;
                    }
                }
            
            }
        }
    }

    .social-links {
        display:inline-flex;
        align-items:center;
        padding:20px 0 0 0;
        @include breakpoint(medium) {
            padding:10px 0 10px;
        }
        a {
            display:flex;
            align-items:center;
            justify-content:center;
            margin:0 12px;
            color:#fff;
            font-size:24px;
            svg {
                width:24px;
                fill:#fff;
            }
        }
    }

    .legal {
        color: rgba(255, 255, 255, 0.50);
        font-size: 14px;
        font-family: $font-family-secondary;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .copyright, .affiliation p {
        @include breakpoint(small only) {
            font-size:14px;
            line-height:14px;
        }
    }
}