.full-player {
    display:none;
    @include breakpoint(large) {
        display:block;
    }

    .btn-mobile-close-recents, .btn-show-mobile-recents, .btn-view-more-mobile, .btn-mobile-close-episodes, .btn-show-mobile-episodes, .btn-show-mobile-shows {
        display:none;
    }

    .header {
        display:none;
        justify-content:space-between;
        align-items:center;
        border-radius: 20px 20px 0px 0px;
        background: rgba(0, 0, 0, 0.25);
        height:98px;
        padding:0px 30px;

        @include breakpoint(large) {
            display:flex;
        }
    }
    .header-left {
        display:flex;
        align-items:center;
        width:50%;
    }
    .station-label {
        color: #FFF;
        font-size: 20px;
        font-weight:900;
        margin-right:10px;
    }
    .station-select {
        font-weight:700;
        background-color:#fff;
        color:$brand-dark;
        border-radius:50px;
        height:40px;
        width:200px;
        font-family: $font-family-sans-serif;
        margin:0px;
        padding:0px 30px 0px 15px;
        border:none;
    }
    .header-right {
        display:flex;
        align-items:center;
        justify-content:flex-end;
        width:50%;

        .btn-minimize-player {
            display:flex;
            margin-left:25px;
            transition:all .1s;
            &:hover {
                transform:scale(.9);
            }
        }

    }
    .volume-control {
        display:flex;
    }
    .volume-slider {
        width: 200px;
        accent-color:#ffffff;
    }
    #btn-volume {
        display:flex;
        .icon-volume, .icon-volume-muted {
            width:35px;
        }
        .icon-volume-muted {
            display:none;
        }
        &.muted {
            .icon-volume {
                display:none;
            }
            .icon-volume-muted {
                display:block;
            }
        }
    }

    .full-player-main {
        height: calc(100% - 98px);
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        &::-webkit-scrollbar {
            width: 5px;
            height: 100%;
            background: rgba(255,255,255,0.25)
        }
        
        &::-webkit-scrollbar-thumb {
            background: rgba(255,255,255,0.7)
        }
    }

    .main {
        position:relative;
        display:flex;
        justify-content:space-between;
        padding:30px 30px 0px;
        // height:calc(100% - 300px);
        min-height:500px;
    }
    .main-left {
        display: flex;
        flex-direction:column;
        flex: 1;
        padding-right:20px;
        @include breakpoint(medium) {
            padding-right:30px;
        }
        @include breakpoint(large only) {
            width:50%;
            padding-right:20px;
        }
        @include breakpoint(xlarge) {
            padding-right:50px;
        }
    }
    .on-air-header {
        padding-bottom:20px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        @include breakpoint(xlarge) {
            padding-bottom:50px;
        }

        .title {
            font-weight:800;
        }
    }
    .on-air-live {
        position: relative;
        display:none;
        font-size:21px;
        align-items:center;
        border-radius:70px;
        border:3px solid #fff;
        color:#fff;
        font-family:$font-family-sans-serif;
        font-weight:900;
        padding:0 15px;
        height:40px;
        @include breakpoint(xxlarge) {
            display:flex;
        }
        .dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 12px;
            background: #FF4343;
            border-radius: 50%;
            -webkit-animation: pulse 4s infinite;
            animation: pulse 4s infinite;
        }
    }
    .on-air-wrap {
        padding-right:0px;
        @include breakpoint(xlarge) {
            padding-right:10px;
        }
        .on-air-image {
            width:104px;
            height:104px;
        }
        .subheading {
            font-size:14px;
            margin-bottom:4px;
        }
        .title {
            font-size:30px;
            line-height:30px;
        }
        .subtitle {
            font-size:21px;
            line-height:23px;
        }
    }
    .current-media {
        display:flex;
        align-items:center;
        justify-content:space-between;
        @include breakpoint(large) {
            flex-wrap:wrap;
        }
        @include breakpoint(xxlarge) {
            flex-wrap:nowrap;
        }
    }
    .current-song {
        display:flex;
        align-items:center;
        .album-art {
            width:170px;
            height:170px;
            margin-right:10px;
            transition:all .2;
            @include breakpoint(large) {
                width:160px;
                height:160px;
            }
            @include breakpoint(xxlarge) {
                width:275px;
                height:275px;    
                margin-right:20px;
            }
        }
        .subheading {
            display:flex;
            align-items:center;
            margin-bottom:5px;
            color: rgba(255, 255, 255, 0.60);
            font-size: 22px;
            line-height:24px;
            font-family:$font-family-sans-serif;
            font-weight:900;
            text-transform: uppercase;
            .audio-icon {
                margin-right:10px;
                opacity:.6;
                display:inline-block;
            }
        }
        .title {
            color: #FFF;
            font-family:$font-family-sans-serif;
            font-size: 40px;
            line-height:40px;
            font-weight:900;
            @include breakpoint(xxlarge) {
                font-size: 57px;
                line-height:60px;
            }
            &:hover {
                text-decoration:underline;
            }
        }
        .subtitle {
            color: #FFF;
            font-size: 24px;
            line-height:26px;
            font-family:$font-family-sans-serif;
            @include breakpoint(xxlarge) {
                font-size: 40px;
                line-height:40px;
            }
            a:hover {
                text-decoration:underline;
            }
        }
    }
    .controls {
        @include breakpoint(large) {
            margin:20px auto 0;
        }
        @include breakpoint(xxlarge) {
            margin:0;
        }
        .play {
            width:115px;
            height:115px;
        }
        .icon-vote, .icon-step {
            height:57px;
        }
    }
    .podcast-controls {
        .btn-jump:before {
            font-size:12px;
            // top:calc(50% + 2px);
        }
    }

    .main-right {
        width:550px;
        @include breakpoint(large only) {
            width:50%;
        }
    }
    .recently-played-wrap {
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.20);
        padding:25px 25px 0px;
        height:100%;
        overflow:hidden;
        
        .heading {
            display:flex;
            align-items: center;
            justify-content: space-between;
            font-size:30px;
            line-height:30px;
            color:#fff;
            font-weight:900;
            margin-bottom:20px;
        }
    }

    .recently-played-list {
        height:100%;
        overflow:hidden;
        overflow-y:scroll;
        &::-webkit-scrollbar {
            width: 5px;
            height: 100%;
            background: rgba(255,255,255,0.25)
        }
        
        &::-webkit-scrollbar-thumb {
            background: rgba(255,255,255,0.7)
        }
    }
    .recently-played-item {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:33px;
        color: #fff;
        padding-right:30px;

        &:last-child {
            margin-bottom:80px;
        }

        // Hide the first item in the list since it's shown in the main section
        &:not(.podcast-tile):first-child {
            display:none;
        }
        .left-content {
            display:flex;
            align-items:center;
            padding-right:10px;
        }
        .album-art {
            width: 100px;
            height: 100px;
            margin-right:10px;
            max-width:none;
        }
        .recently-played-item-title {
            font-size:26px;
            line-height:26px;
            font-weight:800;
            display:block;
            color:#fff;
            &:hover {
                text-decoration:underline;
            }
        }
        .recently-played-item-artist {
            font-size:19px;
            line-height:21px;
            margin-bottom:5px;
            display:block;
            a {
                color:#fff;
            }
            a:hover {
                text-decoration:underline;
            }
        }
        .recently-played-item-time {
            font-size:19px;
        }

    }

    .podcast-section {
        display:flex;
        padding-bottom:30px;
        padding-left:30px;
        .heading {
            font-size:30px;
            font-weight:900;
            color:#fff;
        }
        .slick-prev.slick-disabled, .slick-next.slick-disabled{
            display:none !important;
        }

        .podcast-section-inner {
            position: relative;
            width:100%;
            overflow:hidden;
        }

        .podcast-tile {
            cursor: pointer;
            display:flex;
            align-items:center;
            border-radius: 12px;
            background: rgba(255, 255, 255, 0.20);
            margin-right:30px;
            overflow:hidden;
            color:#fff;
            padding-right:20px;
            width:300px;
            @include breakpoint(large) {
                width:400px;
            }

            &:hover {
                .image-wrap:before, .image-wrap:after {
                    opacity: 1;
                }
            }

            .post-tile-content {
                width:calc(100% - 145px);
            }

            .title {
                font-size:24px;
                line-height:24px;
                font-weight:800;
                color: #FFF;
                margin:0px;
            }
            .author {
                font-size:19px;
                line-height:21px;
                color: #FFF;
                margin:0px;
            }

            .image-wrap {
                position:relative;
                width:130px;
                height:130px;
                margin-right:15px;
            }

        }
    }
    .on-air-switch {
        display:none;
        width:380px;
        padding-right:30px;
        .top {
            display:flex;
            justify-content:space-between;
            align-items:baseline;
        }
        .on-air-live {
            transform:scale(.7);
        }
        .show-info {
            display:flex;
            align-items:center;
            &:hover {
                .image-play-hover:before, .image-play-hover:after {
                    opacity: 1;
                }
            }
            .image-wrap {margin-right:12px;}
            img {
                align-self:center;
            }
            color:#fff;
            .title {
                font-size:26px;
                line-height:26px;
                margin-bottom:4px;
                font-weight:900;
            }
            .subtitle {
                font-size:19px;
                line-height:21px;
            }
        }
    }
}

.main-podcast {
    .current-media .show-info {
        .title {
            font-size:40px;
            line-height:42px;
        }
    }

    .podcast-album-art-wrap {
        position: relative;
        margin-right:10px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.35);
            z-index: 1;
            opacity:0;
            transition: all .2s;
        }
    
        &:after {
            content: url("../../images/icon-play-circle.svg");
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            opacity: 0;
            transition: all .3s;
            z-index: 10;
            width: 50px;
            height: 50px;    
        }
        &:hover:before {
            opacity: 1;
        }
        &:hover:after {
            opacity: 1;
        }

        .album-art {
            margin-right:0px;
        }
    }

    .recently-played-list {
        padding-bottom:60px;
    }

    .recently-played-item {
        cursor: pointer;
        &.active {
            background:rgba(255, 255, 255, 0.65);
            color:$brand-dark;
            .recently-played-item-title, .recently-played-item-artist {
                color:$brand-dark;
            }
        }
        .recently-played-item-title, .recently-played-item-artist {
            &:hover {text-decoration:underline;}
        }
        .recently-played-item-title {
            font-size:18px;
            line-height:20px;
            display:block;
            color:#fff;
        }
        .recently-played-item-artist {
            font-size:16px;
            line-height:18px;
            margin-top:4px;
            display:block;
            color:#fff;
        }
    }
    #btn-load-more-episodes {
        display:flex;
        width:120px;
        margin:0 auto 30px;
        &.loading {
            cursor:wait;
            opacity:.8;
        }
    }

}

.image-play-hover {
    position:relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
        z-index: 1;
        opacity:0;
        transition: all .2s;
    }
    &:after {
        content: url("../../images/icon-play-circle.svg");
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: all .3s;
        z-index: 10;
        width: 50px;
        height: 50px                
    }

}

// .volume-control {
//     position: absolute;
//     width: 100%;
//     height: 7px;
//     background: rgba(255,255,255,0.3);
//     border-radius: 4px;
//     top: 0px;
//     z-index: 4
// }

// .volume-slider {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     width: 100%;
//     height: 7px;
//     background: rgba(255,255,255,0);
//     outline: none;
//     position: absolute;
//     z-index: 12
// }

// .volume-slider::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #fff;
//     cursor: pointer;
//     border: none
// }

// .volume-slider::-moz-range-thumb {
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #fff;
//     border: none;
//     cursor: pointer
// }

// .volume-slider::-ms-thumb {
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #fff;
//     border: none;
//     cursor: pointer
// }