#header {
    position: fixed;
    width:100%;
    z-index:10;
    top:0px;
    background:#fff;
    padding-top:20px;
    padding-bottom:20px;
    height:75px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
    transition:all .3s;

    .header-container {
        display:flex;
        align-items:center;
        justify-content: space-between;
    
    }

    .logo {
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
        width:113px;
    }

    .btn-donate {
        @include breakpoint(small only) {
            font-size:.8rem;
            svg {
                width:14px;
                height:12px;
            }
        }
    }
    .header-cta-link {
        display:none;
        font-size:16px;
        color:$brand-dark;
        padding-right:15px;
        @include breakpoint(medlarge) {
            display:inline-block;
        }

        .icon {
            display:inline-block;
            margin-right:5px;
            width:22px;
        }
    }
    #btn-menu {
        padding-top:4px;
    }

    .alert-bar {
        display:flex;
        justify-content:center;
        align-items:center;
        position:fixed;
        left:0px;
        top:0px;
        width:100%;
        height:40px;
        padding:0 20px;
        background:$primary-color;
        text-align:center;
        overflow:hidden;
        color:#fff;
        transition:all .3s;
        transform:translateY(-100%);

        p {
            margin:0px;
            font-size:14px;
        }

        .btn-close-alert {
            cursor:pointer;
            position:absolute;
            right:0px;
            top:0px;
            color:#fff;
            font-size: 20px;
            padding: 4px 15px;
        }
    }
}