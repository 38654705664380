.elements__podcastfeedelement {
    padding-bottom:25px;
    @include breakpoint(large) {
        padding-bottom:65px;
    }

    .slider-container {
        padding:0px;
        width:calc(100vw - ((100vw - 100%) / 2));
    }

    // For First Slide Shadow
    .blog-slider {
        width: calc(100% + 20px);
        transform: translateX(-20px);
    }

    .slick-list {
        overflow:visible;
    }

    .slick-track {
        display:flex;
    }

    .slick-arrow {
        display:none !important;
        transform:translateY(calc(-50% - 25px));
        @include breakpoint(medium) {
            display:inline-block !important;
        }
        &.slick-disabled {
            color:rgba(0,0,0,0.2);
        }
    }
    .slick-prev {
        left:20px;
        @include breakpoint(large) {
            left:30px;
        }
    }
    .slick-next {
        right:20px;
        @include breakpoint(large) {
            right:30px;
        }
    }
    .header {
        padding-bottom:20px;
        display:flex;

        @include breakpoint(medium) {
            align-items:center;
        }

        .button {
            display:none;
            @include breakpoint(medium) {
                display:inline-block;
            }
        }

        svg {
            width:34px;
            flex-shrink:inherit;
            @include breakpoint(medium) {
                width:38px;
            }
        }

    }


    .heading {
        padding:0 15px;
        margin:0px;
        @include breakpoint(medium) {
            font-size:40px;
            line-height:40px;
        }
    }
    .slick-slide {height:inherit;padding-bottom:35px;transition:all;}
    // .slick-slide:has(+ .slick-current) {
    //     transform:translateX(-30px);
    // }
    .post-tile {
        display:block;
        position:relative;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
        height:100%;
        margin-left:22px;
        @include breakpoint(medium) {
            width:340px;
        }
        &:hover {
            .btn-play-episode {
                opacity:1;
                transform: translateY(0);
            }
        }

        // &.first {
        //     @include breakpoint(medium) {
        //         margin-left:22px;
        //     }
        // }

        .post-tile-content {
            padding:30px;
            border-radius:0 0 20px 20px;
            background:#fff;

            p {
                font-family: $font-family-serif;
                color:#4C6070;
            }
        }
        img {
            width:100%;
            border-radius:20px 20px 0 0;
        }
        .title {
            font-size:32px;
            line-height:32px;
            color:$black;
        }
        p.author {
            font-size:22px;
            line-height:22px;
            margin:0px;
            color:$black;
            font-family: $font-family-sans-serif;
        }
    }
    .date-tile {
        position:absolute;
        top:15px;
        right:15px;    
    }
    .btn-view-all-mobile {
        display:inline-block;
        transform:translateY(-10px);
        @include breakpoint(medium) {
            display:none;
        }
    }

    .tile-link {
        display:block;
        height:100%;
        width:100%;
        position:absolute;
        top:0;
        left:0;
        z-index:1;

    }

    .actions {
        position: relative;
        z-index:10;
        display:flex;
        justify-content:space-between;
        align-items:flex-end;
        margin-top:20px;
        @include breakpoint(medium) {
            margin-top:30px;
        }
    }
    .count {
        display:flex;
        align-items:center;
        .response-count {
            font-size:28px;
            font-weight:900;
            letter-spacing:-1px;

            &.hope {
                color:$hope;
            }
            &.prayer {
                color:$prayer;
            }
        }
    }

    .image-wrap {
        position:relative;
    }

}