.event-tile-wrap {
    display: block;
    max-width: 470px;
    float: right;
    margin-left: 30px;
    margin-bottom: 30px;

    &.left {
        float: left;
        margin-right: 30px;
    }

    .date, .category {
        font-family: $font-family-sans-serif;
    }
}