.ArtistsPage, .SongsPage, .RecentlyPlayedPage {

    .search-form {
        position:relative;
        padding-right:90px;
        input {
            box-shadow:none;
            border-radius:30px 0px 0px 30px;
            padding-left:20px;
        }
        .button {
            position:absolute;
            right:0px;
            top:0px;
            width:110px;
            height:44px;
        }
    }

    .alphabet-list {
        display:flex;
        justify-content:space-between;
        padding-top:5px;
        padding-bottom:30px;
        @include breakpoint(medium) {
            padding-left:20px;
            paddinb-bottom:70px;
        }
    }
    .btn-letter {
        display:inline-flex;
        align-items:center;
        justify-content:center;
        width:35px;
        height:35px;
        font-weight:900;
        color:$brand-dark;
        border-radius:5px;
        transition: all .2s;
        &:hover {
            background:#D5DFE3;
            opacity:.8;
        }

        &.active {
            background:#D5DFE3;
        }
    }

    h2 {
        margin-bottom:30px;
    }

    .biography, .lyrics-wrap {
        margin-bottom:60px;
        p {
            font-family:$font-family-secondary;
        }
    }

    .loader {
        font-size:30px;
        text-align: center;
    }

    .responsive-embed {
        margin-bottom:60px;
        iframe {
            @include rounded-corners;
        }
    }

    .artist {
        display:flex;
        align-items:center;
        padding-top:20px;
        @include breakpoint(medium) {
            padding-top:40px;
        }
        @include breakpoint(large) {
            padding-top:60px;
        }
        img {
            margin-right:20px;
            border-radius:50%;
        }
    }
    .song {
        display:flex;
        align-items:center;
        padding-bottom:20px;
        @include breakpoint(medium) {
            padding-bottom:40px;
        }
        @include breakpoint(large) {
            padding-bottom:60px;
        }
        .image {
            flex-shrink: 0;
            margin-right:12px;
            @include breakpoint(medium) {
                margin-right:20px;
            }
        }
        img {
            width:120px;
            @include breakpoint(medium) {
                width:180px;
            }
            @include breakpoint(large) {
                width:250px;
            }
        }
        a {
            color:$brand-dark;
            &:hover {
                color: $brand-dark;
                text-decoration:underline;
            }
        }
        .title {
            font-weight:900;
            font-size:30px;
            line-height:30px;
            margin-bottom:4px;
            @include breakpoint(medium) {
                font-size:48px;
                line-height:50px;
            }
        }
        .artist-name {
            font-size:18px;
            line-height:20px;
            color:$brand-dark;
            margin-bottom:0px;
            @include breakpoint(medium) {
                font-size:28px;
                line-height:30px;
            }
        }
    }

    .load-more-trigger {
        width:100%;
        height:60px;
        font-size:30px;
        text-align:center;
    }

    // Recent Songs
    .toolbar {
        margin-bottom:30px;
        @include breakpoint(medium) {
            margin-bottom:50px;
        }        
    }
    #recent-songs-form {
        display: flex;
        max-width: 350px;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            max-width: 650px;
            flex-wrap: nowrap;
        }

        .dropdown-wrap {
            margin-right: 10px;
        }
        .dropdown-wrap.day-wrap {
            margin-right: 20px;
        }

        select {
            border-radius:10px;
            font-weight:900;
            min-width:60px;
        }

        .buttons-wrap {
            width:100%;
            @include breakpoint(medium) {
                margin-left:10px;
                width:auto;
            }

            .button {
                margin-right:10px;
            }
        }
    }
}
.RecentlyPlayedPage {
    .time {
        flex:1;
        text-align:right;
        font-size:18px;
        color:$brand-dark;
        padding-left:20px;
        @include breakpoint(medium) {
            font-size:28px;
        }
    }
}