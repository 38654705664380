.blog-actions {
    position: relative;
    z-index:9;
    display:flex;
    justify-content:flex-start;
    align-items:flex-end;
    margin-top:20px;
    @include breakpoint(medium) {
        margin-top:30px;
    }

    .count {
        position: relative;
        display:flex;
        align-items:center;
        margin-left:20px;
        .response-count {
            position: relative;
            overflow: hidden;
            width: 30px;
            height: 40px;
            font-size:28px;
            font-weight:900;
            letter-spacing:-1px;
            font-family: $font-family-sans-serif;

            &.hope {
                color:$hope;
            }
            &.prayer {
                color:$prayer;
            }
            &.increase {
                .track {transform: translateY(-42px);}
            }
        }
        .track {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 0px;
            left: 0px;
            transition: all .8s;
            transition-delay: .4s;
        }
        

        .icon-reaction {
            position:absolute;
            transition:all .6s ease;
            opacity:1;
            &.float {
                transform:translateY(-50px) scale(1.75);
                opacity:0;
            }
        }
    }

}
