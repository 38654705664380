@mixin font-size-line-height($font-size,$line-height){
	@if($font-size){
		font-size: $font-size;
	}

	@if($line-height){
		line-height: $line-height;
	}
}


@mixin gotham-narrow ($font-weight)
{
	font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
	font-weight: $font-weight;
	font-style: normal;
}



@mixin gotham-narrow-light ($font-size: false, $line-height:false)
{
	@include gotham-narrow(300);
	@include font-size-line-height($font-size, $line-height);
}

@mixin gotham-narrow-book ($font-size: false, $line-height:false)
{
	@include gotham-narrow(400);
	@include font-size-line-height($font-size, $line-height);
}


@mixin gotham-narrow-medium ($font-size: false, $line-height:false)
{
	@include gotham-narrow(500);
	@include font-size-line-height($font-size, $line-height);
}


@mixin gotham-narrow-bold ($font-size: false, $line-height:false)
{
	@include gotham-narrow(700);
	@include font-size-line-height($font-size, $line-height);
}
@mixin gotham-narrow-xbold ($font-size: false, $line-height:false)
{
	@include gotham-narrow(800);
	@include font-size-line-height($font-size, $line-height);
}
